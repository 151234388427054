import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader.js';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';

import { setNodeColor, setNodeTexture } from './utils';

const Scene = ({ width, height, metalColor, feltColor, isPreview }) => {
  const MAIN_NODE_NAME = 'Verrundung118_2';
  const FELT_NODE_NAME = 'Verrundung118_3';

  const sceneMount = useRef(null);
  const trumpetRef = useRef(null);
  const sceneRef = useRef(null);
  const rendererRef = useRef(null);
  const cameraRef = useRef(null);
  const controlsRef = useRef(null);
  const pmremGeneratorRef = useRef(null);

  let renderRequested = false;

  const resizeRendererToDisplaySize = () => {
    const canvas = rendererRef.current.domElement;
    const width = canvas.clientWidth;
    const height = canvas.clientHeight;
    const needResize = canvas.width !== width || canvas.height !== height;
    if (needResize) {
      rendererRef.current.setSize(width, height, false);
    }
    return needResize;
  };

  const getCubeMapTexture = (pmremGenerator, texturePath) => {
    return new Promise((resolve, reject) => {
      new RGBELoader().setDataType(THREE.UnsignedByteType).load(
        texturePath,
        (texture) => {
          const envMap = pmremGenerator.fromEquirectangular(texture).texture;
          pmremGenerator.dispose();

          resolve({ envMap });
        },
        undefined,
        reject
      );
    });
  };

  const setupEnvironment = (path, scene, pmremGenerator) => {
    return new Promise((resolve, reject) => {
      getCubeMapTexture(pmremGenerator, path)
        .then(({ envMap }) => {
          scene.environment = envMap;
          scene.environment.textureEncoding = THREE.sRGBEncoding;
          requestRenderIfNotRequested();
          resolve();
        })
        .catch((e) => {
          reject();
        });
    });
  };

  const setupScene = () => {
    let scene = new THREE.Scene();
    // const fov = (0.8 * 180) / Math.PI;

    let camera = new THREE.PerspectiveCamera(60, width / height, 0.01, 1000);

    let renderer = new THREE.WebGLRenderer({ alpha: true, antialias: true });
    renderer.physicallyCorrectLights = true;
    renderer.setClearColor(0x000000, 0);
    renderer.setSize(width, height);
    renderer.toneMappingExposure = 1.0;
    renderer.outputEncoding = THREE.sRGBEncoding;

    // const light1 = new THREE.AmbientLight(0xffffff, 0);
    // scene.add(light1);

    pmremGeneratorRef.current = new THREE.PMREMGenerator(renderer);
    pmremGeneratorRef.current.compileEquirectangularShader();

    sceneMount.current.appendChild(renderer.domElement);

    let controls = new OrbitControls(camera, renderer.domElement);
    controls.enableZoom = false;
    controls.update();

    setupEnvironment('/tool/desat.hdr', scene, pmremGeneratorRef.current);

    const dracoLoader = new DRACOLoader()
      .setDecoderPath('/tool/wasm/')
      .setDecoderConfig({ type: 'js' });
    const ktx2Loader = new KTX2Loader()
      .setTranscoderPath('/tool/wasm/')
      .detectSupport(renderer);

    const loader = new GLTFLoader()
      .setDRACOLoader(dracoLoader)
      .setKTX2Loader(ktx2Loader)
      .setMeshoptDecoder(MeshoptDecoder);

    loader.loadAsync('/tool/SaxMax GLTF/SaxMax.gltf').then((gltf) => {
      const loader = document.getElementById('loader');
      loader.style.display = 'none';
      console.log('gltf_ready');
      const object = gltf.scene;

      const box = new THREE.Box3().setFromObject(object);
      const size = box.getSize(new THREE.Vector3()).length();
      const center = box.getCenter(new THREE.Vector3());

      object.position.x += object.position.x - center.x;
      object.position.y += object.position.y - center.y;
      object.position.z += object.position.z - center.z;

      controls.maxDistance = size * 10;
      camera.near = size / 100;
      camera.far = size * 100;
      camera.updateProjectionMatrix();
      console.log('gltf-processing-finished');

      trumpetRef.current = object;
      setMetalColor();

      // camera.position.copy(center);
      // camera.position.x += size / 2.0;
      // camera.position.y += size / 5.0;
      // camera.position.z += size / 2.0;
      // camera.lookAt(center);

      scene.add(object);
    });

    camera.position.z = 0.1;

    controls.addEventListener('change', requestRenderIfNotRequested);
    renderer.domElement.addEventListener('change', requestRenderIfNotRequested);
    window.addEventListener('resize', requestRenderIfNotRequested);
    window.addEventListener('load', requestRenderIfNotRequested);

    sceneRef.current = scene;
    rendererRef.current = renderer;
    cameraRef.current = camera;
    controlsRef.current = controls;
    threeRender();
  };

  const setMetalColor = () => {
    let colorHex = 0xf5e38d;
    let envPath = '/tool/sunset.hdr';

    if (metalColor === 'silver') {
      colorHex = 0xfafafa;
      envPath = '/tool/desat.hdr';
    }

    setNodeColor(trumpetRef.current, MAIN_NODE_NAME, colorHex);
    requestRenderIfNotRequested();
    // setupEnvironment(envPath, sceneRef.current, pmremGeneratorRef.current).then(() => {
    //   setNodeColor(trumpetRef.current, MAIN_NODE_NAME, colorHex);
    //   requestRenderIfNotRequested();
    // });
  };

  const textureForColor = (color) => {
    const textures = {
      green: '/tool/FeltTextures/Fabric034_4K_Color GREEN.jpg',
      green2: '/tool/FeltTextures/Fabric034_4K_Color GREEN2.jpg',
      red: '/tool/FeltTextures/Fabric034_4K_Color RED.jpg',
      black: '/tool/FeltTextures/Fabric034_4K_Color BLACK.jpg',
      white: '/tool/FeltTextures/Fabric034_4K_Color WHITE.jpg',
      purple: '/tool/FeltTextures/Fabric034_4K_Color PURPLE.jpg',
    };
    return textures[color];
  };

  const setFeltColor = (color) => {
    const texturePath = textureForColor(color);
    const loader = new THREE.TextureLoader();
    loader.load(texturePath, (texture) => {
      setNodeTexture(
        trumpetRef.current,
        FELT_NODE_NAME,
        texture,
        THREE.sRGBEncoding
      );
      requestRenderIfNotRequested();
    });
  };

  const requestRenderIfNotRequested = () => {
    if (!renderRequested) {
      renderRequested = true;
      requestAnimationFrame(threeRender);
    }
  };

  const threeRender = () => {
    renderRequested = undefined;

    if (resizeRendererToDisplaySize() && rendererRef.current) {
      const canvas = rendererRef.current.domElement;
      cameraRef.current.aspect = canvas.clientWidth / canvas.clientHeight;
      cameraRef.current.updateProjectionMatrix();
    }

    controlsRef.current.update();
    rendererRef.current.render(sceneRef.current, cameraRef.current);
  };

  useEffect(() => {
    setupScene();
  }, []);

  useEffect(() => {
    if (!trumpetRef.current) return;
    setMetalColor(metalColor);
  }, [metalColor]);

  useEffect(() => {
    if (!trumpetRef.current) return;
    setFeltColor(feltColor);
  }, [feltColor]);

  useEffect(() => {
    requestRenderIfNotRequested();
  });

  return (
    <div className='loaderWrapper'>
      <div className='lds-dual-ring' id='loader'></div>
      <div className={'threeScene'} ref={sceneMount}></div>
    </div>
  );
};

export default Scene;
