export const traverseMaterials = (object, callback) => {
  object.traverse((node) => {
    if (!node.isMesh) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    materials.forEach(callback);
  });
};

export const getNodeMaterial = (object, nodeName, callback) => {
  object.traverse((node) => {
    if (!node.isMesh) return;
    if (node.name !== nodeName) return;
    const materials = Array.isArray(node.material)
      ? node.material
      : [node.material];
    callback(materials);
  });
};

export const setNodeTexture = (object, nodeName, texture, encoding) => {
  getNodeMaterial(object, nodeName, (materials) => {
    materials.forEach((mat) => {
      mat.map = texture;
      mat.needsUpdate = true;
    });
  });
};

export const setNodeColor = (object, nodeName, colorHex) => {
  getNodeMaterial(object, nodeName, (materials) => {
    materials.forEach((mat) =>
      mat.color.setHex(colorHex).convertSRGBToLinear()
    );
  });
};
